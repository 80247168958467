<template>
  <custom-dialog ref="dialog" title="Excluir notícia" @submit="submit()">
    <span class="text-body-1">
      Atenção! Você está prestes a excluir esta notícia.
    </span>

    <loader-hover v-if="loading" />
  </custom-dialog>
</template>

<script>
import { deleteNew } from "@/services/league/news.js";
import { displayAlert } from "@/utils";

export default {
  data() {
    return {
      loading: false,
      id: "",
    };
  },

  methods: {
    async submit() {
      try {
        this.loading = true;

        await deleteNew(this.id).then(() => {
          this.$emit("success");
          this.close();
        });
      } catch (err) {
        this.displayAlert(err.data.message, 1);
      } finally {
        this.loading = false;
      }
    },

    async handleDialog(event) {
      this.id = event;
      await this.$refs.dialog.openDialog();
    },

    close() {
      this.$refs.dialog.closeDialog();
    },

    displayAlert,
  },
};
</script>

<style></style>
