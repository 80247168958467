<template>
  <v-container>
    <div
      class="d-flex align-space-between align-sm-center justify-space-between flex-column flex-sm-row mb-8"
    >
      <header-view :title="'Notícias'" class="mb-4 mb-sm-0" />

      <v-btn
        color="primary"
        class="black--text"
        depressed
        @click="publishNew()"
      >
        Publicar nova
      </v-btn>
    </div>

    <filter-input
      class="mb-8"
      :dataFilters="require('@/assets/league/filters/schools.json')"
    />

    <custom-table
      :headers="headers"
      :items="news"
      :pagination="pagination"
      :loading="loading"
      @update:pagination="handlePagination($event)"
    >
      <template v-slot:[`item.createdAt`]="{ item }">
        {{ formatDate(item.createdAt) }}
      </template>

      <template v-slot:[`item.updatedAt`]="{ item }">
        {{ formatDate(item.updatedAt) }}
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-btn class="rounded-lg mr-1" icon small @click="editNew(item)">
          <v-icon>mdi-pencil</v-icon>
        </v-btn>

        <v-btn class="rounded-lg" icon small @click="deleteNew(item.id)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </custom-table>

    <PublishNew ref="publishNew" @success="getData()" />

    <EditNew ref="editNew" @success="getData()" />

    <DeleteNew ref="deleteNew" @success="getData()" />
  </v-container>
</template>

<script>
import { getNews } from "@/services/league/news.js";
import { formatDate } from "@/utils";
import PublishNew from "@/components/league/news/PublishNew.vue";
import EditNew from "@/components/league/news/EditNew.vue";
import DeleteNew from "@/components/league/news/DeleteNew.vue";

export default {
  data() {
    return {
      loading: true,
      search: "",
      pagination: {
        page: 0,
        size: 20,
        total: 1,
      },
      headers: [
        {
          text: "Título",
          value: "title",
        },
       /*  {
          text: "Descrição",
          value: "description",
        }, */
        {
          text: "Data de criação",
          value: "createdAt",
        },
        {
          text: "Data de atualização",
          value: "updatedAt",
        },
        {
          value: "actions",
        },
      ],
      news: [],
    };
  },

  components: {
    PublishNew,
    EditNew,
    DeleteNew,
  },

  beforeMount() {
    this.getData();
  },

  watch: {
    ["$route.query"](value) {
      this.handleQuery(value);
    },
  },

  methods: {
    async getData(filter = {}) {
      try {
        const payload = {
          page: this.pagination.page,
          size: this.pagination.size,
          ...filter,
        };

        await getNews(payload).then((res) => {
          this.news = res.news;
          this.pagination.total = res.total;
        });
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },

    publishNew() {
      this.$refs.publishNew.handleDialog();
    },

    editNew(value) {
      this.$refs.editNew.handleDialog(value);
    },

    deleteNew(value) {
      this.$refs.deleteNew.handleDialog(value);
    },

    handleQuery(value) {
      this.loading = true;
      this.pagination.page = 0;
      this.getData(value);
    },

    handlePagination(event) {
      this.pagination.page = event;
      this.getData();
    },

    formatDate,
  },
};
</script>

<style></style>
