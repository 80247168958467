<template>
  <custom-dialog ref="dialog" title="Editar notícia" @submit="submit()">
    <v-form v-model="valid" ref="form">
      <div @keypress.enter.prevent="submit()">
        <avatar-pick v-model="file" ref="thumb" :current="form.thumb" />

        <custom-input
          v-model="form.title"
          ref="title"
          label="Títuto"
          placeholder="Título da notícia"
        />

        <custom-text-area
          v-model="form.description"
          ref="description"
          label="Descrição"
          placeholder="Digite uma descrição"
        />
      </div>
    </v-form>

    <loader-hover v-if="loading" />
  </custom-dialog>
</template>

<script>
import { updateNew } from "@/services/league/news.js";
import { fileUpload } from "@/services/league/files.js";
import { displayAlert } from "@/utils";

export default {
  data() {
    return {
      loading: false,
      valid: false,
      id: "",
      file: null,
      form: {
        thumb: "",
        title: "",
        description: "",
      },
    };
  },

  methods: {
    async submit() {
      if (!this.$refs.form.validate() || !this.$refs.thumb.validate()) return;

      try {
        this.loading = true;

        if (!!this.file) {
          const formData = new FormData();
          formData.append("file", this.file);

          await fileUpload(formData).then(async (res) => {
            if (res.files.length <= 0)
              throw new Error("Falha ao salvar imagem");

            this.form.thumb = res.files[0].file_url;
          });
        }

        const payload = {
          id: this.id,
          body: {
            ...this.form,
          },
        };

        await updateNew(payload).then(() => {
          this.$emit("success");
          this.close();
        });
      } catch (err) {
        this.displayAlert(err.data.message, 1);
      } finally {
        this.loading = false;
      }
    },

    async handleDialog(event) {
      this.id = event.id;

      await this.$refs.dialog.openDialog();
      this.startForm(event);
    },

    startForm(event) {
      this.$refs.thumb.reset();
      this.$refs.title.handleInput(event.title);
      this.$refs.description.handleInput(event.description);
      this.form.thumb = event.thumb;
      
      this.$refs.form.resetValidation();
    },

    close() {
      this.$refs.dialog.closeDialog();
    },

    displayAlert,
  },
};
</script>

<style></style>
