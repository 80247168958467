import { request } from "./index";

export const getNews = (data) =>
  request("get", "/news", {
    params: data,
  });

export const publishNew = (data) => request("post", `/news`, data);

export const updateNew = (data) =>
  request("put", `/news/${data.id}`, data.body);

export const deleteNew = (data) => request("delete", `/news/${data}`);
